export const route_sign_in = '/sign/in'
export const route_sign_up = '/sign/up'
export const router_insta_auth = '/insta-auth'
export const router_view_grant = '/grant/:id'

export const allOpt = 'All'
export const grantStatusOptions = ['draft', 'live', 'expired']
export const disciplineOptions = [
  'Dance',
  'Film',
  'Music',
  'Performance Art',
  'Writing',
  'Theatre',
  'Visual Art',
  'Other'
]

export const locationStatesOptions = [
  allOpt,
  'Non-US country',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington, D.C',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

export const stateList = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  OUS: 'Outside of the United States'
}
export const eventTypes = [
  'Classes and Workshops',
  'Performances and Viewings',
  'Discussions',
  'Special Events',
  'Other'
]

export const donationTypes: any = {
  crowdfunding: 'Crowdfunding',
  donation_to_artist: 'General Fundraising',
  grant: 'Grant',
  ticket: 'Ticket',
  donation_to_the_field: 'The Field',
  donation_to_the_field_crowd: 'The Field Crowdfunding',
  in_kind_donation_to_artist: 'In kind to artist',
  _null: 'N/A'
}
export const donationProductTypes: any = {
  crowdfunding: 'crowdfunding',
  donation_to_artist: 'artist',
  donation_to_the_field: 'field'
}

export const paymentTypes: any = {
  card: 'Credit card',
  paypal: 'Paypal',
  check: 'Check',
  ach: 'ACH',
  manual: 'Manual',
  _null: 'N/A'
}

export const membershipTypes: any = {
  paid: 'Fee Paid',
  pending: 'Pending Sponsorship',
  approved: 'Sponsorship Approved',
  active: 'Sponsorship Active',
  expired: 'Sponsorship Expired',
  free: 'Free Profile'
}

export const membershipPlans: any = {
  year: 'Annually',
  month: 'Monthly',
  _null: 'N/A'
}

export const typeRequest: { [k: string]: string } = {
  grant_application_review: 'Grant Proposal/Application',
  benefit_event: 'Benefit Event Invitation',
  solicitation_letter:
    'Solicitation/Fundraising Letter',
  letter_of_inquiry:
    'Letter of Inquiry',
  grant: 'The Field Grant Listing'
}

export const typeShortRequest: { [k: string]: string } = {
  grant_application_review: 'Grant Proposal/Application',
  benefit_event: 'Benefit Event Invitation',
  solicitation_letter:
      'Solicitation/Fundraising Letter',
  letter_of_inquiry:
      'Letter of Inquiry',
  grant: 'The Field Grant Listing'
}

export const statusTypes: any = {
  processing: {
    label: 'Processing',
    value: 'processing',
    color: 'blue'
  },
  pending: {
    label: 'Pending',
    value: 'pending',
    color: 'warning'
  },
  paid: {
    label: 'Paid',
    value: 'paid',
    color: 'success'
  },
  failed: {
    label: 'Failed',
    value: 'failed',
    color: 'red'
  },
  check_pending: {
    label: 'Check pending',
    value: 'check_pending',
    color: 'cyan'
  }
}

const tupel = (name: string, value: string) => ({ name, value })
export const timezones = [
  tupel('ACT', 'Australia/Darwin'),
  tupel('AET', 'Australia/Sydney'),
  tupel('AGT', 'America/Argentina/Buenos_Aires'),
  tupel('ART', 'Africa/Cairo'),
  tupel('AST', 'America/Anchorage'),
  tupel('BET', 'America/Sao_Paulo'),
  tupel('BST', 'Asia/Dhaka'),
  tupel('CAT', 'Africa/Harare'),
  tupel('CNT', 'America/St_Johns'),
  tupel('CST', 'America/Chicago'),
  tupel('CTT', 'Asia/Shanghai'),
  tupel('EAT', 'Africa/Addis_Ababa'),
  tupel('ECT', 'Europe/Paris'),
  tupel('IET', 'America/Indiana/Indianapolis'),
  tupel('IST', 'Asia/Kolkata'),
  tupel('JST', 'Asia/Tokyo'),
  tupel('MIT', 'Pacific/Apia'),
  tupel('NET', 'Asia/Yerevan'),
  tupel('NST', 'Pacific/Auckland'),
  tupel('PLT', 'Asia/Karachi'),
  tupel('PNT', 'America/Phoenix'),
  tupel('PRT', 'America/Puerto_Rico'),
  tupel('PST', 'America/Los_Angeles'),
  tupel('SST', 'Pacific/Guadalcanal'),
  tupel('VST', 'Asia/Ho_Chi_Minh'),
  tupel('EST', '-05:00'),
  tupel('MST', '-07:00'),
  tupel('HST', '-10:00')
] // consult: https://blog.csdn.net/fhf2424045058/article/details/112556964

// human readable simple timezones
export const timezoneH = [
  { label: 'Pacific Standard Time', value: 'PST', text: 'America/Los_Angeles' },
  { label: 'Central Standard Time', value: 'CST', text: 'America/Chicago' },
  { label: 'Eastern Standard Time', value: 'EST', text: 'America/Detroit' },
  { label: 'European Central Time', value: 'ECT', text: 'Europe/Paris' },
  { label: 'Hawaii Standard Time', value: 'HST', text: 'Pacific/Honolulu' },
  { label: 'Universal Coordinated Time', value: 'UCT', text: 'UTC' },
  { label: 'Greenwich Mean Time', value: 'GMT', text: 'GMT' },
  { label: 'Eastern European Time', value: 'EET', text: 'Europe/Athens' },
  { label: 'China Taiwan Time', value: 'CTT', text: 'Asia/Shanghai' }
]

export const timezoneHText = (val: string) => timezoneH.find((_) => _.value === val)?.text
export const timezoneShort = (val: string) => timezoneH.find((_) => _.text === val)?.value || val
export const mobileSize = 1024
export enum UserRole {
  ADMIN = 'administrator',
  DONOR = 'donor',
  ARTIST = 'artist',
  PREMIUM = 'premium'
}

export const membershipStatus: any = {
  in_review: { text: 'Pending review', color: 'orange' },
  approved: { text: 'Approved', color: 'green' },
  rejected: { text: 'Declined', color: 'orange' },
  paid: { text: 'Fee paid', color: '' },
  wait_for_signature: { text: 'Waiting for Signature', color: 'blue' },
  completed: { text: 'Active', color: 'blue' },
  expired: { text: 'Expired', color: 'red' },
  deleted: { text: 'Deleted', color: 'red' },
  no_apply: { text: 'No apply', color: 'gray' }
}
