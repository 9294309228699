//created by Peggy on 2021/3/29
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Alert, Button, Divider, Input, Select, Spin, Tag } from "antd";
import DatePicker from "@/components/DatePicker";

import HomeLayout from "@/components/layouts/HomeLayout";
import {
  disciplineOptions as options,
  locationStatesOptions,
} from "@/types/enum";

import grant_img from "@/images/grant_default.jpeg";

import styled from "styled-components";
import dayjs from "dayjs";
import { grantList } from "@/api/grant-api";
import SocialLayout from "@/components/layouts/SocialLayout";
import { TheField } from "@/components/GoogleTranslate";
const ListContainer = styled.div``;
const getDescription = (item: any) => {
  const description =
    item.summary ||
    (item.description?.startsWith("<p>") ? "" : item.description);
  return description?.length > 200
    ? description.substr(0, 200) + "..."
    : description;
};
const limit = 10;

const List = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showResult, setShowResult] = useState(false);

  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<any>({
    keyword: "",
    sort: "DEADLINE",
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const getList = (page = 1) => {
    setLoading(true);
    const { skipReq, ...req } = params;
    req.limit = limit;
    req.page = page;
    grantList(req)
      .then((data: any) => {
        const list = page > 1 ? tableData.concat(data.grants) : data.grants;
        setTableData(list);
        setHasMore(data.total > list.length);
        setTotal(data.total);
        setLoading(false);
        data.grants.length && setPage(page);
        if (
          params?.keyword ||
          params?.discipline ||
          params?.createDate ||
          params?.location ||
          params?.sort !== "DEADLINE"
        ) {
          setShowResult(true);
        }
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    if (!params.skipReq) {
      getList();
    }
  }, [params]);
  return (
    <HomeLayout>
      <ListContainer className="home-event-list-container home-grant-list-container common-container">
        {/* <Alert message="The Field will be out of the office from December 25th to January 1st - resuming work on Tuesday, January 2nd, 2024. Please allow additional time for a response from our team during this period. If you intend on submitting a grant for review please take this into consideration when planning your application timeline." closable type="warning" showIcon/> */}
        <h1>Grants and Opportunities</h1>
        <p>
          Find funding for your next project or a residency across the globe.
          Browse our list of opportunities recommended by <TheField/>.
        </p>
        <div className="flex search-wrap">
          <div className="field-form-items">
            <span>Grant keyword</span>
            <Input.Search
              onSearch={(e) => {
                getList();
              }}
              placeholder="Enter grant keyword"
              value={params.keyword}
              onChange={(e) =>
                setParams({
                  ...params,
                  keyword: e.target?.value,
                  skipReq: true,
                })
              }
              onPressEnter={(e: any) => {
                getList();
              }}
            />
          </div>
          <div className=" field-form-items">
            <span>Eligible location</span>
            <Select
              allowClear
              mode="multiple"
              placeholder="Select all that apply"
              defaultActiveFirstOption
              value={params.location}
              onChange={(e) => {
                setParams({
                  ...params,
                  location: e,
                });
              }}
            >
              {locationStatesOptions.map((l) => (
                <Select.Option key={`l-${l.replace(/\s/, "-")}`} value={l}>
                  {l}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>Discipline</span>
            <Select
              bordered={false}
              placeholder="Please select "
              mode="multiple"
              value={params.discipline}
              onChange={(e) => {
                setParams({
                  ...params,
                  discipline: e,
                });
              }}
            >
              {options.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>Deadline</span>
            <DatePicker.RangePicker
              dropdownClassName="date-picker-mobile"
              disabledDate={(current) => {
                return current && current < dayjs().startOf("day");
              }}
              value={params.deadline}
              allowClear
              onChange={(e) => {
                const deadline = e || ["", ""];
                setTableData([]);
                setParams({
                  ...params,
                  deadline,
                });
              }}
            />
          </div>
          <div className="field-form-items clear">
            <div
              onClick={(e) => {
                setParams({
                  keyword: "",
                  sort: "DEADLINE",
                  skipReq: false,
                });
                setShowResult(false);
              }}
            >
              CLEAR
            </div>
          </div>
        </div>
        <div className="search-text">
          Search by discipline, geographic area, or keywords. Note that grants
          marked with <b>[Fiscal Sponsorship]</b> require 501c3 status or Fiscal
          Sponsorship; click on the listing for full eligibility details.
        </div>
        <Divider />
        <p className="flex result-wrap">
          <span>
            {showResult && (
              <>
                <i>Showing</i> {tableData.length} results
              </>
            )}
          </span>
          <span>
            Sort by :{" "}
            <Select
              style={{ width: 136 }}
              bordered={false}
              value={params.sort}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  sort: e,
                });
              }}
            >
              <Select.Option value="DEADLINE">Deadline</Select.Option>
              <Select.Option value="A_Z">
                Alphabetical A-Z
              </Select.Option>
              <Select.Option value="Z_A">
                Alphabetical Z-A
              </Select.Option>
              <Select.Option value="RECENT">
                Most Recently Updated
              </Select.Option>
            </Select>
          </span>
        </p>
        <div className="grant-list-wrap">
          {tableData.map((item) => (
            <div className="grant-list-cell">
              <div className="grant-tags">
                {item.requireSponsorship && (
                  <Tag color="blue" className="sponsor-tag">
                    Fiscal Sponsorship
                  </Tag>
                )}
                {item.deadline &&
                  dayjs(item.deadline).diff(dayjs(), "days") < 10 &&
                  dayjs(item.deadline).diff(dayjs(), "days") > 0 && (
                    <Tag color="red">Deadline Approaching</Tag>
                  )}
              </div>
              <img
                src={item.thumb || grant_img}
                alt=""
                onClick={() =>
                  window.open(
                    `/home/grant/${item.id}/${item.name.replace(/[^\w]/g, "-")}`, '_blank'
                  )
                }
                className="img"
              />

              <div className="flex column start align-start right-item">
                <h2>
                  {item.name} {item.organization && '-'} {item.organization}
                </h2>
                <ul>
                  <li>
                    <p>
                      <span>Deadline</span>
                      <i>
                        {item.rolling ||
                          dayjs(item.deadline).format("MM/DD/YYYY")}
                      </i>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Award</span>
                      <i>{item?.award}</i>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span style={{wordBreak:'break-all'}}>Discipline</span>
                      <i>{(item?.discipline || "").replace(/[,，]/g, " • ")}</i>
                    </p>
                  </li>
                </ul>
                <div className="grant-detail-text">{getDescription(item)}</div>
                <div
                  className="link"
                  onClick={()=> window.open(`/home/grant/${item.id}/${item.name.replace(
                    /[^\w]/g,
                    "-"
                  )}`, '_blank')}
                >
                  view detail {" >"}
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="loading-wrap">
          {loading && <Spin /> ? (
            ""
          ) : (
            <Button
              type="primary"
              disabled={!hasMore}
              style={{ marginTop: "20px" }}
              onClick={() => getList(page + 1)}
            >
              load more
            </Button>
          )}
        </p>
      </ListContainer>
      <SocialLayout />
    </HomeLayout>
  );
};

export default List;
